import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 21 18" {...props}>
    <path d="M20.3873 5.89L19.3373 1.52C19.1173 0.62 18.3373 0 17.4273 0H15.2173H13.2073H11.4873H9.48731H7.76731H5.74731H3.53731C2.63731 0 1.84731 0.63 1.63731 1.52L0.587311 5.89C0.347311 6.91 0.567311 7.95 1.20731 8.77C1.28731 8.88 1.39731 8.96 1.48731 9.06V16C1.48731 17.1 2.38731 18 3.48731 18H17.4873C18.5873 18 19.4873 17.1 19.4873 16V9.06C19.5773 8.97 19.6873 8.88 19.7673 8.78C20.4073 7.96 20.6373 6.91 20.3873 5.89ZM5.50731 2L4.92731 6.86C4.84731 7.51 4.32731 8 3.71731 8C3.22731 8 2.91731 7.71 2.78731 7.53C2.52731 7.2 2.43731 6.77 2.53731 6.36L3.53731 2H5.50731ZM17.3973 1.99L18.4473 6.36C18.5473 6.78 18.4573 7.2 18.1973 7.53C18.0573 7.71 17.7573 8 17.2573 8C16.6473 8 16.1173 7.51 16.0473 6.86L15.4673 2L17.3973 1.99ZM13.9973 6.52C14.0473 6.91 13.9273 7.3 13.6673 7.59C13.4373 7.85 13.1173 8 12.7073 8C12.0373 8 11.4873 7.41 11.4873 6.69V2H13.4473L13.9973 6.52ZM9.48731 6.69C9.48731 7.41 8.93731 8 8.19731 8C7.85731 8 7.54731 7.85 7.30731 7.59C7.05731 7.3 6.93731 6.91 6.97731 6.52L7.52731 2H9.48731V6.69ZM16.4873 16H4.48731C3.93731 16 3.48731 15.55 3.48731 15V9.97C3.56731 9.98 3.63731 10 3.71731 10C4.58731 10 5.37731 9.64 5.95731 9.05C6.55731 9.65 7.35731 10 8.26731 10C9.13731 10 9.91731 9.64 10.4973 9.07C11.0873 9.64 11.8873 10 12.7873 10C13.6273 10 14.4273 9.65 15.0273 9.05C15.6073 9.64 16.3973 10 17.2673 10C17.3473 10 17.4173 9.98 17.4973 9.97V15C17.4873 15.55 17.0373 16 16.4873 16Z" />
  </Svg>
);

export default Icon;
